.TCPill {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;
    border-radius: 20px;
    border: 1px solid var(--trashie-ui-faded-black-30);
    background: var(--trashie-ui-crisp-white);
    padding: 8px 12px;
    cursor: default;

    p {
        font-size: 13px;
        font-weight: 500;
        line-height: 150%;
        margin: 0;
        color: var(--trashie-primary-code-blue);
        transform: translateY(1px);
    }

    &.earn {
        padding: 4px 6px;
        border: 1px solid var(--trashie-ui-faded-black-30);

        p {
            font-size: 12px;
            line-height: 100%;
            color: var(--trashie-ui-faded-black-100);
        }
    }
}

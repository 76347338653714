.ShippingAndReward {
    width: 100%;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;

        &--coupon {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;

            &--description {
                color: var(--trashie-ui-faded-black-80);
                font-family: "GT Maru";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
            }
        }
    }
}
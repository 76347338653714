@import "../../../../../../scss/trashie";

.ShippingInfo {
    padding-bottom: 16px;

    &__container {
        &--backbutton {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 24px;

            &--link {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                color: var(--trashie-ui-faded-black-60);
                font-family: "GT Maru";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                text-transform: uppercase;

                &:hover {
                    color: var(--trashie-ui-faded-black-60);
                }
            }
        }

        &--title {
            color: var(--trashie-ui-faded-black-100);
            font-family: "GT Maru";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            text-transform: initial;
        }

        &--content {
            &--cardContainer {
                padding: 32px 8px;
                display: flex;
                justify-content: center;
                height: 100%;
            }

            &--cards {
                margin-top: 8px;
                display: flex;
                flex-direction: column;
                gap: 8px;

                &--card {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 16px;
                    align-self: stretch;

                    &--icon {
                        img {
                            width: 43.2px;
                            height: 43.2px;
                        }
                    }

                    &--description {
                        &--title {
                            color: var(--trashie-ui-faded-black-100);
                            text-align: center;
                            font-family: "GT Maru";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 150%;
                            text-transform: capitalize;
                        }

                        &--text {
                            color: var(--trashie-ui-faded-black-70);
                            text-align: center;
                            font-family: "GT Maru";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 150%;
                            text-transform: none;
                        }

                    }
                }

                &--qrCard {
                    @extend .ShippingInfo__container--content--cards--card;
                    max-height: 350px;

                    &--qr {
                        width: 100%;
                    }
                }

                &--tbbinfo {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 16px;
                    align-self: stretch;

                    @container (max-width: 402px) {
                        gap: 8px;
                    }
                }

                &--shippingInfo {
                    display: flex;
                    flex-direction: row;
                    gap: 16px;

                    @container (max-width: 402px) {
                        flex-direction: column;
                        gap: 8px;
                    }
                }

            }
        }

        &--ctacontainer {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            gap: 16px;
            margin-top: 16px;

            @container (max-width: 402px) {
                flex-direction: column;
                gap: 8px;
            }
        }

        &--footer {
            display: flex;
            flex-direction: column;
            margin-top: 32px;
            gap: 8px;

            &--title {
                color: var(--trashie-ui-faded-black-100);
                font-family: "GT Maru";
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: capitalize;
            }

            &--content {
                display: flex;
                flex-direction: row;
                padding: 16px;
                align-items: center;
                gap: 16px;
                align-self: stretch;
                border-radius: 8px;
                background: var(--trashie-ui-crisp-white);
                box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);

                &--description {

                    &--title {
                        color: var(--trashie-ui-faded-black-100);
                        font-family: "GT Maru";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        text-transform: capitalize;
                    }

                    &--text {
                        color: var(--trashie-ui-faded-black-50, );
                        font-family: "GT Maru";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        text-transform: none;
                    }
                }
            }

        }

    }
}

@media screen and (max-width: 1020px) {
    .ShippingInfo {
        padding-bottom: 16px;

        &__container {

            &--content {

                &--cards {
                    &--card {
                        gap: 8px;
                    }

                    &--tbbinfo {
                        gap: 8px;
                    }

                    &--shippingInfo {
                        flex-direction: column;
                        gap: 8px;
                    }
                }
            }

            &--ctacontainer {
                flex-direction: column;
                gap: 8;
            }
        }
    }
}
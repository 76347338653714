@import '../../../../../../../scss/trashie.scss';

.ReadyToShip {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid var(--trashie-ui-faded-black-30);
  background-color: var(--trashie-ui-crisp-white);
  border-radius: 20px;
  padding: 24px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);

  &__title {
    font-size: 20px;
    font-weight: 700;
    color: var(--trashie-ui-faded-black-100-b);
    margin: 0;
  }

  &__description {
    font-size: 14px;
    font-weight: 400;
    color: var(--trashie-ui-faded-black-100-b);
    margin: -8px 0 0 0;
  }

  &__secondary-cta {
    color: var(--trashie-primary-code-blue);
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__carrier-icon {
    height: 24px;
    margin-right: 8px;
  }
}

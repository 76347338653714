.SweeptakesPanel {
  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 1;
  background: white;
  border-radius: 8px;
  padding: 24px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1px;
    background: linear-gradient(179.82deg, #FF007A 0.16%, #FFFB5D 99.84%);
    border-radius: inherit;
    z-index: -1;
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
  }

  &__title {
    color: var(--trashie-ui-faded-black-70);
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    white-space: pre-line;
  }

  &__description {
    color: var(--trashie-ui-faded-black-100);
    font-size: 26px;
    font-weight: 500;
    line-height: 150%;
  }

  &__icon-container {
    position: absolute;
    right: -6px;
    top: -6px;

    .IconCircled.medium {
      background: linear-gradient(179.82deg, #FF007A 0.16%, #FFFB5D 99.84%);
    }
  }
}
.InfoSection {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;

    &__title {
        color: var(--trashie-ui-faded-black-100);
        font-family: GT Maru;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        display: flex;
        gap: 8px;
        align-items: center
    }

    &__icon {
        width: 20px;
        height: 25px;
        text-transform: capitalize;
    }

    &__subtitle {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    &__description {
        color: var(--trashie-ui-faded-black-100);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-top: 8px;
    }
}

.textTrasformNone {
    text-transform: none;
}
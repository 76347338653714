.TbbRegisteredDialog {
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
  max-width: 343px;
  width: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &--title {
      color: var(--trashie-ui-faded-black-100);
      text-align: center;
      font-family: 'GT Maru';
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      text-transform: uppercase;
    }

    &--mesages {
      &--message {
        display: flex;
        padding: 8px;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        color: var(--trashie-ui-faded-black-100);
        font-family: 'GT Maru';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

@import "../../../../../scss/trashie";

.GiftCardItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 200px;
  padding: 0;
  border-radius: 12px;
  background: var(--trashie-ui-crisp-white);
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  overflow: hidden;
  position: relative;
  gap: 8px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);

  &:hover {
    transform: scale(1.025);
  }

  &__gem {
    position: absolute;
    top: 6px;
    left: 6px;
    height: 24px;
    width: 24px;
    z-index: 1;
  }

  &__caret {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 12px;
    border: 0;
    padding: 12px;
    border-radius: 8px;
    background: none;

    &--cover {
      display: flex;
      align-self: center;
      align-items: center;
      justify-content: center;
      height: fit-content;
      max-height: 88px;
      overflow: hidden;
      border-radius: 12px;
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);

      &-logo {
        height: 100%;
        max-width: 100%;
      }
    }

    &--content {
      display: flex;
      flex: 1;
      flex-direction: row;
      gap: 4px;
      width: 100%;
      align-items: flex-end;

      &-text {
        display: flex;
        width: 100%;

        color: var(--trashie-ui-faded-black-100);
        font-size: 14px;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-arrow {
        svg {
          transform: rotate(90deg);
        }
      }
    }

    &--cta {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 2px;
      padding: 7px 4px;
      width: 100%;
      border-radius: 100px;
      border: 1px solid var(--trashie-primary-code-blue);
      background: var(--trashie-ui-crisp-white);

      &-label {
        font-size: 12px;
        font-weight: 500;
        line-height: 150%;
        color: var(--trashie-primary-code-blue);
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &.extended {
    height: auto;
    margin: 8px 0; 
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);

    .GiftCardItem__caret {
      padding: 8px 8px 12px;

      &--cover {
        max-height: 133px;
        border-radius: 8px;
      }

      &--content {
        &-text {
          text-transform: none;
        }
      }
    }

    &:hover {
      transform: unset;
    }
  }

  &.non-actionable {
    .GiftCardItem__caret {
      pointer-events: none;
      cursor: default;
    }
  }

  &.disabled {
    .GiftCardItem__caret {
      &--cta {
        border: 1px solid var(--trashie-ui-faded-black-30);

        &-label {
          color: var(--trashie-ui-faded-black-60);
        }
      }
    }
  }
}

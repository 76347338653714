@import '../../../../scss/_trashie.scss';

.icon {
    position: relative !important;
    background-color: unset;
    border: 0;
    height: 36px;
    cursor: pointer;
    transition: transform .5s cubic-bezier(.165, .84, .44, 1);
    display: flex;
    align-items: center;

    &:hover {
        transform: scale(1.1);
    }
}

.cta {
    padding: 10px 16px;
}

.tcIcon {
    height: 13px;
    padding: 0 0 1px 1px;
}

.main {
    z-index: 400;
    width: 375px;
    height: 100%;
    background: var(--trashie-ui-crisp-white);
    align-items: center;
    display: flex;
    position: fixed;
    top: 0;
    right: -500px;
    overflow-y: scroll;
    transition: 850ms;

    &.active {
        right: 0;
        transition: 350ms;
    }

    .container {
        width: 100%;
        height: 100%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border: 0;

        .menuHeader {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 100%;
            padding: 25px 22px;
            gap: 12px;
            border-bottom: 0.5px solid var(--trashie-ui-faded-black-30);

            img {
                height: 33px;
            }

            span {
                font-size: 14px;
                font-weight: 700;
                text-transform: capitalize;
            }

            .close {
                background: var(--trashie-ui-crisp-white);
                border: 0;
                cursor: pointer;
                margin-left: auto;
                padding: 0;
                transition: transform .5s cubic-bezier(.165, .84, .44, 1);

                img {
                    fill: var(--ui-crisp-white);
                    height: 28px;
                }

                &:hover {
                    transform: scale(1.1);
                }
            }

            .userContainer {
                display: flex;
                flex-direction: column;
                width: 70%;

                .userName {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 150%;
                    text-transform: uppercase;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                .userEmail {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 150%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }

        .menu {
            margin: 0;
            padding: 0 22px;
            width: 100%;
            list-style: none;

            .itemContainer {
                display: flex;
                flex-direction: column;
                width: 100%;
                border-width: 0 0 1px;
                border-color: var(--ui-wow-light-gray);
                border-style: solid;

                &:first-of-type {
                    border-width: 1px 0 1px;
                }

                .item {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    padding: 16px 0;
                    gap: 12px;

                    color: var(--ui-faded-black);
                    font-family: GT Maru;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: uppercase;
                    text-decoration: none;

                    img {
                        height: 20px;
                    }

                    &:hover {
                        font-weight: 900;
                    }
                }

                .subMenu {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    list-style: none;
                    padding-bottom: 16px;

                    .subItemContainer {
                        width: 100%;
                        border-radius: 12px;
                        background: var(--trashie-ui-faded-black-10);
                        background-position: right;
                        background-repeat: no-repeat;
                        -webkit-background-size: contain;
                        -moz-background-size: contain;
                        -o-background-size: contain;
                        background-size: contain;

                        &:not(:last-of-type) {
                            margin-bottom: 8px;
                        }

                        .subItem {
                            display: flex;
                            width: 100%;
                            align-items: center;
                            padding: 24px 16px;

                            color: var(--ui-faded-black);
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 150%;
                            text-transform: uppercase;

                            &:hover {
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }

        .myStuff {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 4px;
            padding: 16px 22px;

            .myStuffTitle {
                font-size: 14px;
                font-weight: 700;
                line-height: 150%;
                text-transform: uppercase;
            }

            .myStuffContent {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 8px;

                .myStuffItem {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 4px;
                    flex: 1;

                    text-align: center;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 150%;
                    text-transform: none;

                    .myStuffIcon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 12px;
                        background: var(--trashie-ui-faded-black-20);
                        width: 100%;
                        height: 96px;

                        img {
                            max-height: 50px;
                            max-width: 100%;
                        }
                    }

                    &:hover {
                        font-weight: 700;
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 1020px) {
    .icon {
        gap: 8px;
    }

    .main {
        width: 100%;
        right: -100%;

        .container {
            border-width: 0;

            .menu {
                margin-top: 0;

                .item {
                    border: 0;
                    padding: 16px 0;
                    margin: 0;
                    font-size: 16px;
                }
            }
        }
    }
}
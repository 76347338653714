.DealsPreview {
  width: 100%;
  overflow: hidden;
}

.DealsPreview-DashboardSection__unlock {
  min-height: 327px;
}

@media screen and (max-width: 768px) {
  .DealsPreview-DashboardSection__unlock {
    min-height: unset;
  }
}
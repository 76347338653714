.ExclusiveRewardsCarousel {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__title {
    font-size: 20px;
    font-weight: 700;
    color: var(--trashie-ui-faded-black-100-b);
    margin: 0;
  }
}

.ExploreMoreRewards {
  & .CardSection__container--title {
    font-size: 20px;
    text-transform: initial;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    &--reward-wrapper {
      all: unset;
      width: 100%;
    }

    &--reward-card {
      height: 325px;
    }
  }
}

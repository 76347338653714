@import '../../../../scss/trashie.scss';

.main {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: var(--take-back-bags-banana-runtz);
    min-height: 69px;
    padding: 0 64px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.09);

    .container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        max-width: 1312px;
        margin-left: auto;
        margin-right: auto;

        .text {
            font-family: GT Maru;
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            margin: 0;
        }
    }

    &.notification {
        background-color: var(--trashie-ui-thrifty-sky);
        min-height: 53px;
        padding: 16px 64px;
        text-transform: none;

        .text {
            font-size: 14px;
        }
    }
}

@media only screen and (max-width:1020px) {
    .main {
        padding: 0 20px;
        min-height: 52px;

        .container {
            font-size: 10px;
            height: 52px;

            .text {
                font-size: 10px;
            }
        }

        &.notification {
            background-color: var(--trashie-ui-thrifty-sky);
            padding: 8px 20px;
            min-height: unset;

            .text {
                font-size: 11px;
            }
        }
    }
}
@import "../../../../../../scss/trashie";

.DeviceBatteryWarning {
    width: 100%;
    padding: 0 16px;

    &__container {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &--title {
            color: var(--trashie-ui-faded-black-100);
            font-family: "GT Maru";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            margin-bottom: 4px;
        }

        &--animation {
            width: 100%;
            height: 220px;
            border: none;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &--content {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &--text {
                color: var(--trashie-ui-faded-black-100);
                font-family: "GT Maru";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;

                ul {
                    padding-left: 20px;
                    margin: 0;
                }
            }

            &--checkbox {
                margin: 8px 0;

                .MuiFormControlLabel-label {
                    color: var(--trashie-ui-faded-black-100) !important;
                    font-family: "GT Maru";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                }
            }
        }

        &--contact {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            gap: 8px;
            color: var(--trashie-ui-faded-black-100);
            text-align: center;
            font-family: "GT Maru";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin-top: 16px;
            padding-bottom: 16px;
        }
    }
}
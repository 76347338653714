@import '../../../../../../scss/trashie';

.SelectShippingProviderView {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;

    &>*:first-child {
        margin-bottom: -24px;
    }

    &__items {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__address {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__address-header {
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__address-button {
        width: 20px;
        padding: 0px;
    }

    &__address-icon {
        width: 20px;
    }

    &__address-name {
        font-weight: 700;
        font-size: 11px;
        line-height: 150%;
    }

    &__address-info {
        font-weight: 400;
        font-size: 11px;
        line-height: 150%;
        color: var(--trashie-ui-faded-black-80)
    }
}
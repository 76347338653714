.IconCircled {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--trashie-ui-faded-black-30);
    z-index: 1;

    &.white-logo {
        img {
            filter: brightness(0) invert(1);
        }
    }

    &.small {
        > * {
            max-width: 24px;
            max-height: 24px;
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &.medium {
        width: 60px;
        height: 60px;

        > * {
            max-width: 44px;
            max-height: 44px;
        }

        svg {
            width: 40px;
            height: 40px;
        }
    }

    &.large {
        width: 102px;
        height: 102px;

        > * {
            max-width: 86px;
            max-height: 86px;
        }

        svg {
            width: 82px;
            height: 82px;
        }
    }
}

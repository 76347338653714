@import '../../../../scss/_trashie.scss';

.main {
    background: var(--trashie-ui-crisp-white);
    align-items: flex-start;
    z-index: 200;
    width: 100dvw;
    height: 90px;
    padding: 0 64px;
    display: flex;
    position: fixed;

    .container {
        width: 100%;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        gap: 8px;
        max-width: 1312px;

        .logo {
            float: left;
            text-decoration: none;
            position: relative;

            .img {
                vertical-align: middle;
                display: inline-block;
                height: 40px;
            }
        }

        .title {
            display: flex;
            flex: 1;
            color: var(--trashie-ui-faded-black-100);
            text-align: left;
            font-size: 16px;
            font-weight: 700;
            line-height: 120%;
            text-transform: none;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .ctaContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 48px;

            .cta {
                @include ctaLink;
                font-size: 14px;
                width: auto;
                text-decoration: none;
                font-weight: 500;
                color: var(--trashie-ui-faded-black-100-b);
                display: flex;
                align-items: center;
                gap: 8px;
            }

            .ctaActive {
                color: var(--trashie-primary-code-blue);

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1020px) {
    .main {
        height: 52px;
        padding: 0 16px;
        border-bottom: 0;

        .container {
            .logo {
                .img {
                    height: 32px;
                }
            }
        }

        .shadow {
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.09);
        }
    }
}
.DealCard {
    width: 100%;
    position: relative;

    &__container {
        display: flex;
        border-radius: 14.929px;
        height: 326px;
        align-items: center;
        justify-content: center;

        &--logo {
            max-width: 157px;
            width: 100%;
            filter: brightness(0) invert(1);
        }
    }

    &.circle-variant {
        .DealCard__container {
            &--circle-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                height: 45%;
                aspect-ratio: 1 / 1;
            }

            &--logo {
                max-width: 75%;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .DealCard {
        &__container {
            height: 131px;
    
            &--logo {
                min-width: 64px;
                width: 100%;
            }
        }
    }
}

@media screen and (min-width: 1920px) {
    .DealCard {
        &__container {
            height: 470px;

            &--logo {
                max-width: 226px;
            }
        }
    }
}
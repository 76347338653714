.RewardCard {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
    background-color: var(--trashie-ui-faded-black-10);
    z-index: 1;

    &__top {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .logo {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: flex-start;

            img {
                max-height: 100%;
                max-width: 80%;
                filter: brightness(0) invert(1);
            }
        }

        .total {
            color: var(--trashie-ui-crisp-white);
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
        }
    }

    &__bottom {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 8px;
        text-align: left;

        .description {
            display: flex;
            flex-direction: column;
            color: var(--trashie-ui-crisp-white);
            width: 100%;
            font-weight: 500;
            line-height: 120%;
            text-transform: none;
            gap: 4px;
        }

        .arrow {
            display: flex;
            height: fit-content;
            align-items: flex-end;
            transform: rotate(90deg);
            align-self: flex-end;

            svg {
                fill: var(--trashie-ui-crisp-white);
            }
        }
    }

    &.small {
        border-radius: 8px;
        padding: 12px;

        .RewardCard__top {
            .logo {
                height: 27px;

                img {
                    max-height: 70%;
                }
            }

            .total {
                &.small {
                    width: 42%;
                    font-size: 12px;
                    line-height: 110%;
                    text-align: right;
                }
            }
        }

        .RewardCard__bottom {    
            .description {
                font-size: 10px;
            }
        }
    }

    &.large {
        border-radius: 12px;
        padding: 19px;

        .RewardCard__top {
            .logo {
                height: 42px;
            }

            .total {
                font-size: 28px;
            }
        }

        .RewardCard__bottom {
            .description {
                font-size: 12px;
            }
        }
    }

    &.default {
        align-items: center;

        .RewardCard__top {
            height: 100%;
            justify-content: center;

            .logo {
                justify-content: center;
            }
        }
    }
}

// SIZE_XS
@media screen and (max-width: 576px) {
    .RewardCard {
        &.small {
            padding: 16px;

            .RewardCard__bottom {    
                .description {
                    font-size: 12px;
                    line-height: 150%;
                }
            }
        }
    }
}

@import '../../../../scss/_trashie.scss';

.CircularRewardsCarousel {
  width: 100%;
  overflow: hidden;

  &__wrapper {
    all: unset;
    width: 100%;

    &.disabled {
      pointer-events: none;
      cursor: default;
    }
  }

  &__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &-amount {
      color: var(--trashie-ui-faded-black-90);
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
  }
}
